import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponent } from '../../shared/basecomponent';

@Component({
  selector: 'app-fiberlist',
  templateUrl: './fiberlist.component.html',
  styleUrls: ['./fiberlist.component.scss']
})
export class FiberlistComponent extends BaseComponent implements OnInit {
 fibers: any = []
  fibersAll: any = []
  allTags: any = []
  tags: any = []
  technologies: any = []

  constructor(public injector: Injector) { super(injector) }
  tagList: any = []
  tag = ""
  showTagList = false

  async ngOnInit() {
    this.loader.show();
    await this.getData();
    this.loader.hide();
  }
  async getData() {
    this.fibers = await this.db.get("fiber", "getAllFibers");
    this.fibersAll = this.fibers
    this.allTags = await this.db.get("fiber", "getAllTags");
    this.tagList = []
    this.allTags.forEach(t => {
      let bfound = false
      this.tagList.forEach(tl => {
        if (tl == t.tag) bfound = true
      })
      if (bfound == false) this.tagList.push(t.tag)
    })
    this.technologies = await this.db.get("fiber", "getAllFiberLookups")
    this.fibers.forEach(fiber => {
      fiber.tags = []
      this.allTags.forEach(t => {
        if (t.fiberId == fiber.id) fiber.tags.push(t)
      })
    })
  }
  edit(id: number) {
    this.router.navigate(['/dashboard/fiberedit'], { queryParams: { 'id': id } });
  }
  filter() {
    if (this.tag.length > 2) {
      this.fibers = [];
      this.fibersAll.forEach(c => {
        let bfound = false
        c.tags.forEach(t => {
          if (t.tag == this.tag) bfound = true
        })
        if (bfound) this.fibers.push(c)
      })
    }
  }
  clear() {
    this.fibers = this.fibersAll
  }
  add() {
    this.router.navigate(['/dashboard/fiberedit'], { queryParams: { 'id': 0 } });
  }
}
