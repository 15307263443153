import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponent } from '../../shared/basecomponent';

@Component({
  selector: 'app-coatingedit',
  templateUrl: './coatingedit.component.html',
  styleUrls: ['./coatingedit.component.scss']
})
export class CoatingeditComponent extends BaseComponent implements OnInit {
  coating: any = {}
  allTags: any = []
  coatingApplications: any = []
  coatingBarriers: any = []
  materials
  tag = ""
  tagList: any = []
  materialId = 0

  id = 0
  constructor(public injector: Injector) { super(injector) }

  async ngOnInit() {
    this.loader.show();
    await this.getData();
    this.loader.hide();
  }
  async getData() {
    this.id = this.getIntQueryParamOrDefault('id', 0);
    this.materials = await this.db.get("coating", "getMaterials")
    this.allTags = await this.db.get("coating", "getAllTags");
    this.tagList = []
    this.allTags.forEach(t => {
      let bFound = false;
      this.tagList.forEach(tl => {
        if (tl == t.tag) bFound = true
      })
      if (!bFound) this.tagList.push(t.tag) 
    })

    const resp = await this.db.get("coating", "getAllCoatingLookups")
    this.coatingApplications = resp.coatingApplications
    this.coatingBarriers = resp.coatingBarriers

    if (this.id == 0) {
      this.coating = {
        id: 0,
        name: 'new Coating',
        description: "",
        applicationId: 1,
        barrierId: 1
      }
      return //create new material
    } else {
      this.coating = await this.db.get("coating", "getCoating", { id: this.id });
      this.coating.tags = []
      this.allTags.forEach(t => {
        if (t.coatingId == this.coating.id) this.coating.tags.push(t)
      })
      this.coating.coatingMaterialRel.forEach(r => {
        r.name = ''
        this.materials.forEach(m => {
          if (r.materialId == m.id) {
            r.name = m.name
          }
        })
      });
    }
  }
  async addTag() {
    this.loader.show()
    await this.db.post("coating", "postTag", { CoatingId: this.id,tag: this.tag })
    await this.getData()
    this.loader.hide()
  }
  async removeTag(t) {
    this.loader.show()
    await this.db.delete("coating", "deleteTag", { id: t.id })
    await this.getData()
    this.loader.hide()
  }
  async addMaterial() {
    this.loader.show()
    await this.db.get("coating", "postCoatingMaterialRels", { CoatingId: this.id, MaterialId: this.materialId })
    await this.getData()
    this.loader.hide()
  }
  async removeMaterial(m) {
    this.loader.show()
    await this.db.delete("coating", "deleteCoatingMaterialRels", { Id: m.id})
    await this.getData()
    this.loader.hide()
  }
  async submit() {
    this.loader.show()
    let coatingNew = await this.db.post("coating", "postCoating", this.coating)
    
    await this.getData()
    this.id = coatingNew.id
    this.loader.hide()
  }
}
