import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponent } from '../../shared/basecomponent';

@Component({
  selector: 'app-coatinglist',
  templateUrl: './coatinglist.component.html',
  styleUrls: ['./coatinglist.component.scss']
})
export class CoatinglistComponent extends BaseComponent implements OnInit {
  coatings: any = []
  coatingsAll: any = []
  allTags: any = []
  tags:any = []
  coatingBarriers: any = []
  coatingApplications: any = []
  constructor(public injector: Injector) { super(injector) }
  tagList: any = []
  tag = ""
  showTagList = false

  async ngOnInit() {
    this.loader.show();
    await this.getData();
    this.loader.hide();
  }
  async getData() {
    this.coatings = await this.db.get("coating", "getAllCoatings");
    this.coatingsAll = this.coatings
    this.allTags = await this.db.get("coating", "getAllTags");
    this.tagList = []
    this.allTags.forEach(t => {
      let bfound = false
      this.tagList.forEach(tl => {
        if (tl == t.tag) bfound = true
      })
      if (bfound == false) this.tagList.push(t.tag)
    })

    const resp = await this.db.get("coating", "getAllCoatingLookups")
    this.coatingApplications = resp.coatingApplications
    this.coatingBarriers = resp.coatingBarriers
    this.coatings.forEach(coating => {
      coating.tags = []
      this.allTags.forEach(t => {
        if (t.coatingId == coating.id) coating.tags.push(t)
      })
    })
  }
  edit(id: number) {
    this.router.navigate(['/dashboard/coatingedit'], { queryParams: { 'id': id } });
  }
  filter() {
    if (this.tag.length > 2) {
      this.coatings = [];
      this.coatingsAll.forEach(c => {
        let bfound = false
        c.tags.forEach(t => {
          if (t.tag == this.tag) bfound = true
        })
        if (bfound) this.coatings.push(c)
      })
    }
  }
  clear() {
    this.coatings = this.coatingsAll
  }
  add() {
    this.router.navigate(['/dashboard/coatingedit'], { queryParams: { 'id': 0 } });
  }
}
