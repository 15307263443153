import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { snaAlertComponent, snaAlertService } from './modules/sna-alert/snaAlertModule';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { NgbModule, NgbDropdown, NgbModalModule, NgbCarousel,NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { MasterComponent } from './components/master/master.component';
import { LoginComponent } from './components/login/login.component';
import { HomeComponent } from './components/home/home.component';
import { UsermanagerComponent } from './components/menu-dev/usermanager/usermanager.component';
import { UsereditComponent } from './components/menu-dev/useredit/useredit.component';
import { NewuserComponent } from './components/menu-dev/newuser/newuser.component';
import { ResetpasswordComponent } from './components/resetpassword/resetpassword.component';
import { MenuitemComponent } from './components/master/menuitem/menuitem.component';

// services
import { AccountService } from './components/login/account.service';
import { TokenService } from './components/login/token.service';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { JwtModule } from '@auth0/angular-jwt';
import { AuthGuard } from './guards/auth-guard.service';
import { UsermanagerService } from './components/menu-dev/usermanager/usermanager.service';
import { GlobalService } from './services/global.service';

// directive, module
import { snaAccess, SnaAccessService } from './directives/sna-access.directive';
import { TestComponent } from './components/menu-dev/test/test.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { IdeaeditComponent } from './components/menu-create/ideaedit/ideaedit.component';
import { IdealistComponent } from './components/menu-explore/idealist/idealist.component';
import { ConceptlistComponent } from './components/menu-explore/conceptlist/conceptlist.component';
import { ProjectlistComponent } from './components/menu-explore/projectlist/projectlist.component';
import { DocumentationComponent } from './components/menu-organize/documentation/documentation.component';
import { DevelopmentComponent } from './components/menu-dev/development/development.component';
import { SainputbooleanComponent } from './components/shared/formfields/sa-input-boolean/sa-input-boolean.component';
import { SainputhtmlComponent } from './components/shared/formfields/sa-input-html/sa-input-html.component';
import { DomsanitizerPipe } from './services/domsanitizer.pipe';
import { SaselectComponent } from './components/shared/formfields/sa-select/sa-select.component';
import { SainputtextComponent } from './components/shared/formfields/sa-input-text/sa-input-text.component';
import { DocuComponent } from './components/docu/docu.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { CropperComponent } from './components/cropper/cropper.component';
import { RelsComponent } from './components/rels/rels.component';
import { GraphForcedComponent } from './components/graph-forced/graph-forced.component';
import { SaInputDateComponent } from './components/shared/formfields/sa-input-date/sa-input-date.component';
import { LoaderService } from './modules/loader/loader.service';
import { LoaderComponent } from './modules/loader/loader.component';
import { CircEconomyComponent } from './components/circ-economy/circ-economy.component';
import { SaSelectMultipleComponent } from './components/shared/formfields/sa-select-multiple/sa-select-multiple.component';
import { PackagingSystemsComponent } from './components/menu-organize/packaging-systems/packaging-systems.component';
import { PackSystemShowComponent } from './components/menu-explore/pack-system-show/pack-system-show.component';
import { HtmlEditorService } from './modules/html-editor/html-editor.service';
import { HtmlEditorComponent } from './modules/html-editor/html-editor.component';
import { ProductlistComponent } from './components/menu-explore/productlist/productlist.component';
import { PackagingTechnologyComponent } from './components/menu-create/ideaedit/packaging-technology/packaging-technology.component';
import { SaScoreComponent } from './components/shared/formfields/sa-score/sa-score.component';
import { FormulaComponent } from './components/menu-explore/formula/formula.component';
import { TopideasComponent } from './components/menu-explore/topideas/topideas.component';
import { ProductCriteriaComponent } from './components/product-criteria/product-criteria.component';
import { DeviceDetectorService, DeviceDetectorModule } from 'ngx-device-detector';
import { TakephotoComponent } from './components/takephoto/takephoto.component';
import { DevtasksComponent } from './components/menu-dev/devtasks/devtasks.component';
import { SurveyTemplateManagerComponent } from './components/survey/surveytemplatemanager/surveytemplatemanager.component';
import { PrettyprintPipe } from './pipes/prettyprint/prettyprint.pipe';
import { SurveyComponent } from './components/survey/survey/survey.component';
import { SurveyRatingComponent } from './components/survey/elements/rating/survey-rating.component';
import { SurveyRatingCheckboxComponent } from './components/survey/helper/survey-rating-checkbox/survey-rating-checkbox.component';
import { SurveyCheckboxComponent } from './components/survey/elements/survey-checkbox/survey-checkbox.component';
import { SurveyRadioComponent } from './components/survey/elements/survey-radio/survey-radio.component';
import { SurveyCommentComponent } from './components/survey/elements/survey-comment/survey-comment.component';
import { SurveyEditorComponent } from './components/survey/survey-editor/survey-editor.component';
import { SaInputNumberComponent } from './components/shared/formfields/sa-input-number/sa-input-number.component';
import { Sa2ListManagerComponent } from './components/shared/formfields/sa-2list-manager/sa-2list-manager.component';
import { SaListManagerComponent } from './components/shared/formfields/sa-list-manager/sa-list-manager.component';
import { SPICodesComponent } from './components/menu-explore/spicodes/spicodes.component';
import { MaterialAdjectiveComponent } from './components/menu-explore/material-adjective/material-adjective.component';
import { LookupManagerComponent } from './components/menu-dev/lookup-manager/lookup-manager.component';
import { SaSelect2Component } from './components/shared/formfields/sa-select2/sa-select2.component';
import { SearchComponent } from './components/menu-explore/search/search.component';
import { SaTableComponent } from './components/shared/sa-table/sa-table.component';
import { NetworkGraphComponent } from './components/shared/network-graph/network-graph.component';
import { TestangComponent } from './components/menu-organize/testang/testang.component';
import { TestangchildComponent } from './components/menu-organize/testang/testchild/testangchild/testangchild.component';
import { SaListPickerComponent } from './components/shared/formfields/sa-list-picker/sa-list-picker.component';
import { GenericListComponent } from './components/menu-explore/generic-list/generic-list.component';
import { RecordeditComponent } from './components/menu-create/recordedit/recordedit.component';
import { ConcepteditComponent } from './components/menu-create/conceptedit/conceptedit.component';
import { ProjecteditComponent } from './components/menu-create/projectedit/projectedit.component';
import { ProducteditComponent } from './components/menu-create/productedit/productedit.component';
import { RelationshipComponent } from './components/menu-organize/relationship/relationship.component';
import { WorldmapComponent } from './components/worldmap/worldmap.component';
import { LowerInitialPipe } from './pipes/lowerinitial/lower-initial.pipe';
import { RemoveIdeaComponent } from './components/menu-dev/remove-idea/remove-idea.component';
import { CanvasComponent } from './components/canvas/canvas.component';
import { YesnodialogComponent } from './components/shared/yesnodialog/yesnodialog.component';
import { SurveytakeinComponent } from './components/survey/surveytakein/surveytakein.component';
import { SaRadioComponent } from './components/shared/formfields/sa-radio/sa-radio.component';
import { SaInputHtml2Component } from './components/shared/formfields/sa-input-html2/sa-input-html2.component';
import { S3testComponent } from './components/menu-dev/s3test/s3test.component';
import { SiegelhofComponent } from './components/menu-explore/siegelhof/siegelhof.component';
import { PunchComponent } from './components/menu-explore/punch/punch.component';
import { CalculatorComponent } from './components/menu-explore/calculator/calculator.component';
import { WvtrComponent } from './components/menu-explore/wvtr/wvtr.component';
import { FormulasComponent } from './components/menu-explore/formulas/formulas.component';
import { MagnesiumcontentComponent } from './components/menu-explore/calculator/magnesiumcontent/magnesiumcontent.component';
import { TableperformanceComponent } from './components/menu-explore/calculator/tableperformance/tableperformance.component';
import { PressureresidenceComponent } from './components/menu-explore/calculator/pressureresidence/pressureresidence.component';
import { PressingspeedComponent } from './components/menu-explore/calculator/pressingspeed/pressingspeed.component';
import { MaxcompressionComponent } from './components/menu-explore/calculator/maxcompression/maxcompression.component';
import { TogglerComponent } from './components/shared/toggler/toggler.component';
import { RecordprintComponent } from './components/menu-create/recordprint/recordprint.component';
import { CampaignmenagementComponent } from './components/menu-organize/campaignmenagement/campaignmenagement.component';
import { IdeasurveyComponent } from './components/survey/ideasurvey/ideasurvey.component';
import { FillsurveyComponent } from './components/survey/fillsurvey/fillsurvey.component';
import { SurveyresultsComponent } from './components/survey/surveyresults/surveyresults.component';
import { VbarComponent } from './components/shared/graph/vbar/vbar.component';
import { GraphtestComponent } from './components/menu-dev/graphtest/graphtest.component';
import { HbarComponent } from './components/shared/graph/hbar/hbar.component';
import { LinkitemComponent } from './components/home/linkitem/linkitem.component';
import { XfactortestComponent } from './components/menu-dev/xfactortest/xfactortest.component';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { XfactorComponent } from './components/menu-explore/xfactor/xfactor.component';
import { CompanylistComponent } from './components/menu-organize/companies/companylist/companylist.component';
import { CompanyeditComponent } from './components/menu-organize/companies/companyedit/companyedit.component';
import { FillcircComponent } from './components/survey/fillcirc/fillcirc.component';
import { CompanyoverviewComponent } from './components/admin/company/companyoverview/companyoverview.component';
import { CompanydetailsComponent } from './components/admin/company/companydetails/companydetails.component';
import { CompanyrelsComponent } from './components/admin/company/companyrels/companyrels.component';
import { SurveyoverviewComponent } from './components/admin/surveyoverview/surveyoverview.component';
import { SurveygroupresultsComponent } from './components/admin/surveyoverview/surveygroupresults/surveygroupresults.component';
import { GanttComponent } from './components/gantt/gantt.component';
import { TakesurveyComponent } from './components/survey/takesurvey/takesurvey.component';
import { TypeofideaComponent } from './components/shared/dashboard/typeofidea/typeofidea.component';
import { CircecondashComponent } from './components/menu-explore/circecondash/circecondash.component';
import { FilterPipe } from './pipes/filter/filter.pipe';
import { IdeacategoriesComponent } from './components/menu-create/ideacategories/ideacategories.component';
import { ConfirmComponent } from './modules/confirm/confirm.component';
import { TestsurveyComponent } from './components/survey/testsurvey/testsurvey.component';
import { ProgressComponent } from './components/shared/graph/progress/progress.component';
import { DynformComponent } from './components/shared/dynform/dynform.component';
import { DynformtestComponent } from './components/menu-dev/dynformtest/dynformtest.component';
import { ManagedosageformComponent } from './components/admin/managedosageform/managedosageform.component';
import { ManagelookupsComponent } from './components/admin/managelookups/managelookups.component';
import { RelideacompanyComponent } from './components/menu-explore/relideacompany/relideacompany.component';
import { SaList2PickerComponent } from './components/shared/formfields/sa-list2-picker/sa-list2-picker.component';
import { ReclistComponent } from './components/menu-user/reclist/reclist.component';
import { FavoriteComponent } from './components/shared/favorite/favorite.component';
import { IdeafieldsComponent } from './components/admin/ideafields/ideafields.component';
import { IdeafieldlistComponent } from './components/menu-dev/ideafieldlist/ideafieldlist.component';
import { ManagematerialComponent } from './components/admin/managematerial/managematerial.component';
import { FileComponent } from './components/file/file.component';
import { BrandeditComponent } from './components/menu-organize/brandedit/brandedit.component';
import { BrandlistComponent } from './components/menu-organize/brandlist/brandlist.component';
import { DosageformshowComponent } from './components/menu-explore/dosageformshow/dosageformshow.component';
import { ManageprocessComponent } from './components/admin/manageprocess/manageprocess.component';
import { MaterialshowComponent } from './components/menu-explore/materialshow/materialshow.component';
import { ProcessshowComponent } from './components/menu-explore/processshow/processshow.component';
import { Hbar2Component } from './components/shared/graph/hbar2/hbar2.component';
import { UserproductlistComponent } from './components/menu-user/userproductlist/userproductlist.component';
import { UserideaeditComponent } from './components/menu-user/userideaedit/userideaedit.component';
import { UserproducteditComponent } from './components/menu-user/userproductedit/userproductedit.component';
import { MaterialgroupComponent } from './components/admin/materialgroup/materialgroup.component';
import { MaterialgroupshowComponent } from './components/menu-explore/materialgroupshow/materialgroupshow.component';
import { IdeamaterialpickerComponent } from './components/menu-create/recordedit/ideamaterialpicker/ideamaterialpicker.component';
import { GsearchComponent } from './components/menu-explore/gsearch/gsearch.component';
import { SearchresulttableComponent } from './components/menu-explore/gsearch/searchresulttable/searchresulttable.component';
import { SustopicsComponent } from './components/admin/sustopics/sustopics.component';
import { ManagesustopicsComponent } from './components/admin/managesustopics/managesustopics.component';
import { List3PickerComponent } from './components/shared/formfields/list3-picker/list3-picker.component';
import { StoreeditComponent } from './components/menu-organize/storeedit/storeedit.component';
import { ListPickerDescrComponent } from './components/shared/formfields/list-picker-descr/list-picker-descr.component';
import { ManageinnoproductsComponent } from './components/admin/manageinnoproducts/manageinnoproducts.component';
import { InnpoproductsComponent } from './components/admin/innpoproducts/innpoproducts.component';
import { ExternalReferencesComponent } from './components/menu-explore/external-references/external-references.component';
import { ActiveingredienceComponent } from './components/admin/activeingredience/activeingredience.component';
import { ProductprintComponent } from './components/print/productprint/productprint.component';
import { PrinttextComponent } from './components/print/printtext/printtext.component';
import { PrinthtmlComponent } from './components/print/printhtml/printhtml.component';
import { RelprintComponent } from './components/print/relprint/relprint.component';
import { PrintitemComponent } from './components/print/printitem/printitem.component';
import { PrintlistComponent } from './components/print/printlist/printlist.component';
import { PrintpicsComponent } from './components/print/printpics/printpics.component';
import { DocusingleComponent } from './components/docu/docusingle/docusingle.component';
import { TechnologylistComponent } from './components/menu-explore/technologylist/technologylist.component';
import { TechnologyeditComponent } from './components/menu-create/technologyedit/technologyedit.component';
import { BtobproductlistComponent } from './components/menu-explore/btobproductlist/btobproductlist.component';
import { B2bproducteditComponent } from './components/menu-create/b2bproductedit/b2bproductedit.component';
import { Co2analyzerComponent } from './components/menu-create/recordedit/co2analyzer/co2analyzer.component';
import { Co2impactlistComponent } from './components/menu-organize/co2impactlist/co2impactlist.component';
import { ExploreComponent } from './components/menu-explore/explore/explore.component';
import { TechdataComponent } from './components/menu-create/recordedit/techdata/techdata.component';
import { PrinttechdataComponent } from './components/print/printtechdata/printtechdata.component';
import { MaterialtreeComponent } from './components/admin/materialtree/materialtree.component';
import { MaterialsComponent } from './components/admin/materialtree/materials/materials.component';
import { SubgroupsComponent } from './components/admin/materialtree/subgroups/subgroups.component';
import { MatgroupsComponent } from './components/admin/materialtree/matgroups/matgroups.component';
import { CompanymaterialpickerComponent } from './components/menu-organize/companies/companyedit/companymaterialpicker/companymaterialpicker.component';
import { CertificationsComponent } from './components/menu-organize/certifications/certifications.component';
import { MaterialcompanyrelComponent } from './components/admin/materialtree/materialcompanyrel/materialcompanyrel.component';
import { MaterialsustainComponent } from './components/menu-explore/materialsustain/materialsustain.component';
import { CampaignreportComponent } from './components/survey/campaignreport/campaignreport.component';
import { IdeacompareComponent } from './components/menu-explore/ideacompare/ideacompare.component';
import { ReearchComponent } from './components/menu-explore/reearch/reearch.component';
import { SusgoalComponent } from './components/menu-create/recordedit/susgoal/susgoal.component';
import { IdeasusgoallistComponent } from './components/menu-explore/ideasusgoallist/ideasusgoallist.component';
import {ProjectcharterComponent} from './components/menu-create/projectedit/projectcharter/projectcharter.component';
import { CompanytagsComponent } from './components/menu-organize/companies/companyedit/companytags/companytags.component';
import { MaterialtagsComponent } from './components/admin/materialtree/materials/materialtags/materialtags.component';
import { IdeatagsComponent } from './components/menu-create/recordedit/ideatags/ideatags.component';
import { CoatinglistComponent } from './components/menu-explore/coatinglist/coatinglist.component';
import { CoatingeditComponent } from './components/menu-explore/coatingedit/coatingedit.component'

export function getToken(){
  return sessionStorage.getItem('token');
}

@NgModule({
  declarations: [
    AppComponent,
    CircEconomyComponent,
    ConceptlistComponent,
    CropperComponent,
    DevelopmentComponent,
    DocuComponent,
    DocumentationComponent,
    DomsanitizerPipe,
    GraphForcedComponent,
    HomeComponent,
    IdeaeditComponent,
    IdealistComponent,
    LoaderComponent,
    LoginComponent,
    MasterComponent,
    NewuserComponent,
    PackagingSystemsComponent,
    PackSystemShowComponent,
    ProjectlistComponent,
    RelsComponent,
    ResetpasswordComponent,
    SainputbooleanComponent,
    SaInputDateComponent,
    SainputhtmlComponent,
    SainputtextComponent,
    SaselectComponent,
    SaSelectMultipleComponent,
    snaAccess,
    snaAlertComponent,
    TestComponent,
    UsereditComponent,
    UsermanagerComponent,
    HtmlEditorComponent,
    ProductlistComponent,
    PackagingTechnologyComponent,
    SaScoreComponent,
    FormulaComponent,
    TopideasComponent,
    MenuitemComponent,
    ProductCriteriaComponent,
    TakephotoComponent,
    DevtasksComponent,
    SurveyTemplateManagerComponent,
    SurveyComponent,
    SurveyRatingComponent,
    SurveyRatingCheckboxComponent,
    PrettyprintPipe,
    SurveyCheckboxComponent,
    SurveyRadioComponent,
    SurveyCommentComponent,
    SurveyEditorComponent,
    SaInputNumberComponent,
    Sa2ListManagerComponent,
    SaListManagerComponent,
    SPICodesComponent,
    MaterialAdjectiveComponent,
    LookupManagerComponent,
    SaSelect2Component,
    SearchComponent,
    SaTableComponent,
    NetworkGraphComponent,
    TestangComponent,
    TestangchildComponent,
    SaListPickerComponent,
    GenericListComponent,
    RecordeditComponent,
    ConcepteditComponent,
    ProjecteditComponent,
    ProducteditComponent,
    RelationshipComponent,
    WorldmapComponent,
    LowerInitialPipe,
    RemoveIdeaComponent,
    CanvasComponent,
    YesnodialogComponent,
    SurveytakeinComponent,
    SaRadioComponent,
    SaInputHtml2Component,
    S3testComponent,
    SiegelhofComponent,
    PunchComponent,
    CalculatorComponent,
    WvtrComponent,
    FormulasComponent,
    MagnesiumcontentComponent,
    TableperformanceComponent,
    PressureresidenceComponent,
    PressingspeedComponent,
    MaxcompressionComponent,
    TogglerComponent,
    RecordprintComponent,
    CampaignmenagementComponent,
    IdeasurveyComponent,
    FillsurveyComponent,
    SurveyresultsComponent,
    VbarComponent,
    GraphtestComponent,
    HbarComponent,
    LinkitemComponent,
    XfactortestComponent,
    XfactorComponent,
    CompanylistComponent,
    CompanyeditComponent,
    FillcircComponent,
    CompanyoverviewComponent,
    CompanydetailsComponent,
    CompanyrelsComponent,
    SurveyoverviewComponent,
    SurveygroupresultsComponent,
    GanttComponent,
    TakesurveyComponent,
    TypeofideaComponent,
    CircecondashComponent,
    FilterPipe,
    IdeacategoriesComponent,
    ConfirmComponent,
    TestsurveyComponent,
    ProgressComponent,
    DynformComponent,
    DynformtestComponent,
    ManagedosageformComponent,
    ManagelookupsComponent,
    RelideacompanyComponent,
    SaList2PickerComponent,
    ReclistComponent,
    FavoriteComponent,
    IdeafieldsComponent,
    IdeafieldlistComponent,
    ManagematerialComponent,
    FileComponent,
    BrandeditComponent,
    BrandlistComponent,
    DosageformshowComponent,
    ManageprocessComponent,
    MaterialshowComponent,
    ProcessshowComponent,
    Hbar2Component,
    UserproductlistComponent,
    UserideaeditComponent,
    UserproducteditComponent,
    MaterialgroupComponent,
    MaterialgroupshowComponent,
    IdeamaterialpickerComponent,
    GsearchComponent,
    SearchresulttableComponent,
    SustopicsComponent,
    ManagesustopicsComponent,
    List3PickerComponent,
    StoreeditComponent,
    ListPickerDescrComponent,
    ManageinnoproductsComponent,
    InnpoproductsComponent,
    ExternalReferencesComponent,
    ActiveingredienceComponent,
    ProductprintComponent,
    PrinttextComponent,
    PrinthtmlComponent,
    RelprintComponent,
    PrintitemComponent,
    PrintlistComponent,
    PrintpicsComponent,
    DocusingleComponent,
    TechnologylistComponent,
    TechnologyeditComponent,
    BtobproductlistComponent,
    B2bproducteditComponent,
    Co2analyzerComponent,
    Co2impactlistComponent,
    ExploreComponent,
    TechdataComponent,
    PrinttechdataComponent,
    MaterialtreeComponent,
    MaterialsComponent,
    SubgroupsComponent,
    MatgroupsComponent,
    CompanymaterialpickerComponent,
    CertificationsComponent,
    MaterialcompanyrelComponent,
    MaterialsustainComponent,
    CampaignreportComponent,
    IdeacompareComponent,
    ReearchComponent,
    SusgoalComponent,
    IdeasusgoallistComponent,
    ProjectcharterComponent,
    CompanytagsComponent,
    MaterialtagsComponent,
    IdeatagsComponent,
    CoatinglistComponent,
    CoatingeditComponent
  ],
  entryComponents: [],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    AngularFontAwesomeModule,
    NgbModule.forRoot(),
    JwtModule.forRoot({
      config: {
        tokenGetter: getToken,
        whitelistedDomains: ["localhost:4200"]
      }
    }),
    CKEditorModule,
    ImageCropperModule,
    NgbModalModule,
    DeviceDetectorModule.forRoot(),
    NgxJsonViewerModule
  ],
  providers: [
    AccountService,
    TokenService,
    SnaAccessService,
    UsermanagerService,
    AuthGuard,
    NgbDropdown,
    snaAlertService,
    //MasterService,
    GlobalService,
    LoaderService,
    HtmlEditorService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
